<template>
  <div class="content w-100">
    <nav-header :is_inbound="isInboundProcess" />
    <top-menu v-if="!isInboundProcess && menu_layout === 'top' && !isMobile" />
    <div class="d-flex">
      <side-menu
        v-if="(!isInboundProcess && menu_layout === 'left') || isMobile"
      />
      <div
        class="d-block w-100"
        :class="[
          isInboundProcess && 'my-4',
          enable_application_header && 'content-wrapper px-3',
        ]"
      >
        <div class="container-fluid p-md-0">
          <Breadcrumb v-if="!isInboundProcess && enable_application_header" />
          <div>
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavHeader, TopMenu, SideMenu, Breadcrumb } from "@/components";
import { mapActions, mapGetters } from "vuex";
import { SplashScreen } from "@capacitor/splash-screen";

export default {
  name: "Main",
  data() {
    return {
      isMobile: false,
      isInboundProcess: false,
    };
  },
  components: {
    NavHeader,
    SideMenu,
    TopMenu,
    Breadcrumb,
  },
  computed: {
    ...mapGetters(["menu_layout", "is_mobile", "enable_application_header"]),
  },
  methods: {
    ...mapActions([
      "set_menu_layout",
      "get_all_notifications",
      "notification_websocket_connection",
    ]),
  },
  async mounted() {
    this.isInboundProcess = this.$route.path
      .split("/")
      .includes("inbound-process");

    const layout = localStorage.getItem("layout");
    const splited = layout.split("-");
    if (splited.includes("top")) {
      this.set_menu_layout("top");
    } else {
      this.set_menu_layout("left");
    }
    this.isMobile = this.is_mobile;

    await SplashScreen.show({
      autoHide: true,
    });

    this.get_all_notifications();

    // this.notification_websocket_connection();
  },
  watch: {
    $route: {
      handler() {
        this.isInboundProcess = this.$route.path
          .split("/")
          .includes("inbound-process");
      },
      deep: true,
    },
    is_mobile: {
      handler(isMobile) {
        this.isMobile = isMobile;
      },
      deep: true,
    },
  },
};
</script>
